import * as React from 'react';
import { Flex, Image, Box, Heading, useMediaQuery } from '@chakra-ui/react';
import profileImage from 'src/assets/logo.png';
import CV from 'src/assets/files/CesarRB-CV.pdf';
import { BaseLink } from 'src/components/generic/Link';

export const Profile = (): JSX.Element => {
	const [isDesktopView] = useMediaQuery('(min-width: 832px)');
	const imageRef = React.useRef<HTMLImageElement>(null);
	const [loaded, setLoaded] = React.useState(false);
	React.useEffect(() => {
		if (!loaded && imageRef.current?.complete) {
			setLoaded(true);
		}
	}, []);

	return (
		<>
			{!isDesktopView && (
				<Flex direction={'row'} alignItems="center" justify="space-around" pb={5}>
					<Image
						boxSize="200px"
						borderRadius="full"
						src={profileImage}
						alt={'Profile Image'}
						display={!loaded ? 'none' : 'block'}
						ref={imageRef}
						onLoad={() => {
							setLoaded(true);
						}}
					/>
				</Flex>
			)}
			<Flex direction={'row'} alignItems="center" justify="space-around">
				{isDesktopView && (
					<Image
						boxSize="200px"
						borderRadius="full"
						src={profileImage}
						alt={'Profile Image'}
						display={!loaded ? 'none' : 'block'}
						ref={imageRef}
						onLoad={() => {
							setLoaded(true);
						}}
					/>
				)}

				<Flex direction={'column'}>
					<Box pb={3}>
						<Heading>
							<b>César Represa Bautista </b>
						</Heading>
					</Box>
					<Box>
						<Heading>
							<b>Software Developer</b>
						</Heading>
					</Box>
				</Flex>
				<Flex direction={'column'}>
					<Box pb={3}>
						<BaseLink href={'mailto:contact@cesarb.dev'} label={'contact@cesarb.dev'} bold={true} />
					</Box>
					<Box pb={3}>
						<BaseLink href={'tel:+31684983437'} label={'+31684983437'} bold={true} />
					</Box>
					<Box>
						<BaseLink href={CV} label={'Curriculum Vitae'} bold={true} />
					</Box>
				</Flex>
			</Flex>
		</>
	);
};
