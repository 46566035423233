import * as React from 'react';
import { Profile } from './body/Profile';
import { Tools } from './body/Tools';
import { Content } from './body/Content';
import { Flex } from '@chakra-ui/react';

export const PageBody = (): JSX.Element => {
	return (
		<Flex direction={'column'}>
			<Profile />
			<Tools />
			<Content />
		</Flex>
	);
};
