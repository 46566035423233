import * as React from 'react';
import { chakra, keyframes, ImageProps, forwardRef, usePrefersReducedMotion } from '@chakra-ui/react';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LogoImg = forwardRef<ImageProps & { fileSrc: string }, 'img'>((props, ref) => {
	const prefersReducedMotion = usePrefersReducedMotion();
	const animation = prefersReducedMotion ? undefined : `${spin} infinite 20s linear`;
	return <chakra.img animation={animation} src={props.fileSrc} ref={ref} {...props} />;
});

export const Logo = ({
	fileSrc,
	alt,
	isDesktopView,
}: {
	fileSrc: string;
	alt: string;
	isDesktopView?: boolean;
}): JSX.Element => {
	const h = isDesktopView ? '6vmax' : '3vmax';
	const w = isDesktopView ? '8vmax' : '4vmax';
	return <chakra.img h={h} w={w} src={fileSrc} alt={alt} />;
};

export const AnimatedLogo = ({ fileSrc }: { fileSrc: string }): JSX.Element => {
	return <LogoImg h="10vmin" fileSrc={fileSrc} pointerEvents="none" />;
};
