import * as React from 'react';
import { Flex, Box, useMediaQuery } from '@chakra-ui/react';
import { Logo } from './Logo';
import docker from 'src/assets/tools/docker.svg';
import aws from 'src/assets/tools/aws.svg';
import nodejs from 'src/assets/tools/nodejs.svg';
import mongodb from 'src/assets/tools/mongodb.svg';
import sql from 'src/assets/tools/sql.svg';
import typescript from 'src/assets/tools/typescript.svg';
import php from 'src/assets/tools/php.svg';
import java from 'src/assets/tools/java.svg';
import js from 'src/assets/tools/js.svg';
import linux from 'src/assets/tools/linux.svg';
import git from 'src/assets/tools/git.svg';
import electron from 'src/assets/tools/electron.svg';
import react from 'src/assets/tools/react.svg';
import nginx from 'src/assets/tools/nginx.svg';
import apache from 'src/assets/tools/apache.svg';
import cicd from 'src/assets/tools/cicd.svg';
import k8s from 'src/assets/tools/k8s.svg';
import serverless from 'src/assets/tools/serverless.svg';

const TOOLS = [
	{
		alt: 'docker',
		fileSrc: docker,
	},
	{
		alt: 'k8s',
		fileSrc: k8s,
	},
	{
		alt: 'nodejs',
		fileSrc: nodejs,
	},
	{
		alt: 'react',
		fileSrc: react,
	},
	{
		alt: 'php',
		fileSrc: php,
	},
	{
		alt: 'sql',
		fileSrc: sql,
	},
	{
		alt: 'typescript',
		fileSrc: typescript,
	},
	{
		alt: 'java',
		fileSrc: java,
	},
	{
		alt: 'apache',
		fileSrc: apache,
	},
	{
		alt: 'js',
		fileSrc: js,
	},
	{
		alt: 'linux',
		fileSrc: linux,
	},
	{
		alt: 'electron',
		fileSrc: electron,
	},
	{
		alt: 'git',
		fileSrc: git,
	},
	{
		alt: 'aws',
		fileSrc: aws,
	},
	{
		alt: 'cicd',
		fileSrc: cicd,
	},
	{
		alt: 'nginx',
		fileSrc: nginx,
	},

	{
		alt: 'mongodb',
		fileSrc: mongodb,
	},
	{
		alt: 'serverless',
		fileSrc: serverless,
	},
];

function splitRows() {
	const half = Math.ceil(TOOLS.length / 2);
	return {
		firstHalf: TOOLS.slice(0, half),
		secondHalf: TOOLS.slice(-half),
	};
}

export const Tools = (): JSX.Element => {
	const [isDesktopView] = useMediaQuery('(min-width: 832px)');
	const { firstHalf, secondHalf } = splitRows();
	return (
		<Flex direction={'column'} align="center" justify="center" pt={10} pb={isDesktopView ? 6 : 5}>
			<Flex direction={'row'} pb={isDesktopView ? 5 : 3}>
				{firstHalf.map((tool: { alt: string; fileSrc: string }, i: number) => (
					<Box pl={2} pr={2} key={i}>
						<Logo isDesktopView={isDesktopView} key={tool.alt} alt={tool.alt} fileSrc={tool.fileSrc} />
					</Box>
				))}
			</Flex>
			<Flex direction={'row'}>
				{secondHalf.map((tool: { alt: string; fileSrc: string }, i: number) => (
					<Box pl={2} pr={2} key={i}>
						<Logo isDesktopView={isDesktopView} key={tool.alt} alt={tool.alt} fileSrc={tool.fileSrc} />
					</Box>
				))}
			</Flex>
		</Flex>
	);
};
