import * as React from 'react';
import { Box, Flex, Spinner, useColorMode, useMediaQuery } from '@chakra-ui/react';
import { PageBody } from './PageBody';
import { HeaderBar } from './HeaderBar';
import { useEffect } from 'react';

let flashingCounter = 0;

export const Page = (): JSX.Element => {
	const { setColorMode, colorMode } = useColorMode();
	const [isDesktopView] = useMediaQuery('(min-width: 832px)');
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const firstUpdate = React.useRef(true);
	React.useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			setLoaded(true);
		}
	});

	useEffect(() => {
		setTimeout(() => {
			if (flashingCounter < 3) {
				if (colorMode === 'dark') {
					setColorMode('dark');
				} else {
					setColorMode('light');
				}
			}
			flashingCounter += 1;
		}, 500);
	});

	return (
		<Flex pt={10} pb={20} width="full" direction="column" pr={isDesktopView ? 20 : 5} pl={isDesktopView ? 20 : 5}>
			<Box pb={10} align="center" justify="center">
				<HeaderBar />
			</Box>
			<Box>
				{!loaded && (
					<Spinner thickness="10px" speed="0.65s" emptyColor="gray.500" color="gray.100" size={'xl'} />
				)}
				{loaded && <PageBody />}
			</Box>
		</Flex>
	);
};
