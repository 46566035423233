import * as React from 'react';
import { Box, Flex, chakra, Spacer, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { BaseLink } from 'src/components/generic/Link';
import referenceLetter from 'src/assets/files/Reference-letter.pdf';

const WORK_EXP = {
	plek: {
		job: [
			<BaseLink key={1} href={'https://plek.co/en'} label={'Plek'} />,
			'Backend developer',
			'Jan 2017 – Jan 2020',
			'Amsterdam - Netherlands',
		],
		description: [
			<chakra.span key={12}>
				Plek is an intranet, communication and workplace tool for companies. I learned about the importance of
				code optimization and refactoring to improve quality, readability or performance, to handle memory leaks
				or reduce memory use, touching all the parts of the software development circle (from the design until
				the integration and deployment of the product), working with 3rd party services and different
				technologies like NodeJs, AngularJs, React, Docker, MongoDB, ElasticSearch or Redis.
			</chakra.span>,
			<div key={13}>
				<chakra.span key={13}> Plek also has a </chakra.span>
				<BaseLink href={`https://plek.co/en/app-desktop`} label={`desktop app`} />
				<chakra.span key={14}>
					, which is base on Electron to create native applications for Windows, Mac OSx or Linux users.I was
					the maintainer and also, I developed a system to release new updates automatically when they are
					published.
				</chakra.span>
			</div>,
			<>
				<BaseLink href={'https://iwa-connect.org'} label={'IWA'} />
				<chakra.span key={15}>
					{' '}
					is a global social platform,with subscription and membership features. On this project, I worked
					with external services like GlobalCollect, the payment system of the platform, and Turpin, used to
					send out orders for the magazines that the users buy. The biggest challenge was to improve and
					support the payment system, managing the exchange of currencies and taxes base on each country law.
					New features implemented were a discount system or the creation of new kinds of memberships.
				</chakra.span>
			</>,
		],
	},
	ilumy: {
		job: [
			<BaseLink key={2} href={'https://ilumy.com/'} label={'Ilumy'} />,
			'Backend developer',
			'Jan 2017 – Jan 2020',
			'Amsterdam - Netherlands',
		],
		description: [
			<chakra.span key={11}>
				My role was the setup, configuration and maintenance (core and updates of dependencies, web analytics,
				general support, backups...) of the website. Focused on drupal websites and Typescript projects.
			</chakra.span>,
			<>
				<BaseLink key={3} href={'https://30dagenopzee.nl/'} label={'The Indische Herinneringscentrum'} />
				<chakra.span>
					{' '}
					we made a backend project from scratch with NodeJs and Typescript. The challenge was to code the
					logic to fetch part of the content and routes displayed on the globe from a third party. Also, a CMS
					system was developed to import the rest of the data.
				</chakra.span>
			</>,
			<>
				<BaseLink key={4} href={'http://bigmove.nu/'} label={'The BigMove app'} />
				<chakra.span>
					{' '}
					required to develop a server to communicate the website and mobile app of the client. Implemented
					some features like a real-time chat (one-to-one and multi-room, over WebSocket), a chat bot or a
					cronjob system to trigger actions as sending out emails, push notifications web alerts or chat
					messages.
				</chakra.span>
			</>,
			<>
				<BaseLink
					key={5}
					href={'https://mensenrechten.nl/nl'}
					label={'The College voor de Rechten van de Mens'}
				/>
				<chakra.span> project consists on a drupal website and a custom CMS to publish articles </chakra.span>
				<BaseLink key={8} href={'https://mensenrechten.nl/nl/publicaties'} label={'on the website.'} />
				<chakra.span>
					{' '}
					My main task was the setup, configuration and maintenance (core and dependencies updates, web
					analytics, general support, backups...) of the website. Developed custom drupal modules to fit
					requirements.
				</chakra.span>
			</>,
			<>
				<BaseLink key={6} href={'https://plek.co/'} label={'The Plek.co'} />
				<chakra.span>
					{' '}
					website is another drupal project. I was in charge of the maintenance of the server, deploying
					updates, developing custom modules or helping to integrate the website with SharpSpring
				</chakra.span>
			</>,
		],
	},
	sugerendo: {
		job: [
			<BaseLink key={7} href={'https://www.sugerendo.com/en/'} label={'Sugerendo Sistemas'} />,
			<>
				Programmer analyst <BaseLink key={8} href={referenceLetter} label={'(Reference letter)'} />
			</>,
			'Feb 2016 – Jul 2016',
			'Madrid (Spain)',
		],
		description: [
			<chakra.span key={11}>
				Sugerendo Sistemas is a start-up located in Madrid where between 6 and 10 people work. Sugerendo is
				engaged in consulting and implementation of e-commerce for businesses with free software platforms like
				Magento. The main function was to give support to projects with e-commerce that required a certain
				technical solution. Mainly my function consisted in the development of new modules and features to add
				to the online commerce of each client, in the part of frontend as well as backend.
			</chakra.span>,
			<chakra.span key={13}>
				The development of new modules is done using the web languages PHTML, HTML, PHP and JavaScript. Another
				part of my job was the maintenance and management of online stores, from the server configuration to the
				optimum configuration of the administrator control panel.
			</chakra.span>,
		],
	},
};
const STUDIES = [
	{
		education: 'Computer Engineer Degree',
		location: 'UPSAM - Madrid (Spain)',
		year: 'Sep 2012 – Jun 2016',
	},
	{
		education: 'Aeronautical and Airport Management Certification',
		location: 'Universidad San Pablo CEU - Madrid (Spain)',
		year: 'Sep 2010 - Jun 2012',
	},
	{ education: 'High School', location: 'IES Astorga - Astorga (Spain)', year: 'Sep 2004 - Jul 2010' },
];

const ContentJob = ({ job }: { job: (string | JSX.Element)[] }) => {
	return (
		<>
			{job.map((job, key) => (
				<div key={key}>
					<Box>
						<b>{job}</b>
					</Box>
					<Spacer />
				</div>
			))}
		</>
	);
};

const ContentDescription = ({ description }: { description: JSX.Element[] }) => {
	return (
		<Flex direction={'column'}>
			{description.map((description, key) => (
				<Box pb={4} key={key}>
					{description}
				</Box>
			))}
		</Flex>
	);
};

export const Content = (): JSX.Element => {
	return (
		<Flex direction={'column'} pt={5} pl={15} pr={15} align="center" justifyContent="center">
			<Box>
				<ContentJob job={WORK_EXP.plek.job} />
				<Box>
					<ContentDescription description={WORK_EXP.plek.description} />
				</Box>
			</Box>
			<Box>
				<ContentJob job={WORK_EXP.ilumy.job} />
				<Box>
					<ContentDescription description={WORK_EXP.ilumy.description} />
				</Box>
			</Box>
			<Box>
				<ContentJob job={WORK_EXP.sugerendo.job} />
				<Box>
					<ContentDescription description={WORK_EXP.sugerendo.description} />
				</Box>
			</Box>
			<Box pt={15}>
				<Table variant="simple">
					<Thead>
						<Tr>
							<Th>Education</Th>
							<Th>Location</Th>
							<Th>Year</Th>
						</Tr>
					</Thead>
					<Tbody>
						{STUDIES.map((study, index) => (
							<Tr key={index}>
								<Td>{study.education}</Td>
								<Td>{study.location}</Td>
								<Td>{study.year}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
			</Box>
		</Flex>
	);
};
