import * as React from 'react';
import { useColorModeValue, IconButton } from '@chakra-ui/react';
import { FiGithub, FiGitlab, FiLinkedin } from 'react-icons/all';

export const SocialIcon: (props: { rendericon: string; redirect: string }) => JSX.Element = (props: {
	rendericon: string;
	redirect: string;
}): JSX.Element => {
	const text = useColorModeValue('dark', 'light');

	let SwitchIcon = useColorModeValue(FiGithub, FiGithub);
	if (props.rendericon === 'linkedin') {
		SwitchIcon = useColorModeValue(FiLinkedin, FiLinkedin);
	} else if (props.rendericon === 'gitlab') {
		SwitchIcon = useColorModeValue(FiGitlab, FiGitlab);
	}

	const githubHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		window.open(props.redirect, '_blank');
	};
	return (
		<IconButton
			size="lg"
			fontSize="larger"
			onClick={githubHandler}
			icon={<SwitchIcon />}
			aria-label={`Switch to ${text} mode`}
			{...props}
		/>
	);
};
