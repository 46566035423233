import * as React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { Page } from 'src/components/Page';
import theme from 'src/styles/CustomTheme';

export const App = (): JSX.Element => (
	<ChakraProvider theme={theme}>
		<Box textAlign="center" fontSize="xl">
			<Page />
		</Box>
	</ChakraProvider>
);
