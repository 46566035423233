import { Link, Text } from '@chakra-ui/react';
import * as React from 'react';

export const BaseLink = ({ href, label, bold }: { href: string; label: string; bold?: boolean }): JSX.Element => {
	return (
		<Link color="teal.500" href={href} target="_blank" rel="noopener noreferrer">
			<Text as={'i'}>{bold ? <b>{label}</b> : label}</Text>
		</Link>
	);
};
