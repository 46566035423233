import * as React from 'react';
import { ColorModeSwitcher } from './header/ColorModeSwitcher';
import { SocialIcon } from './header/SocialIcon';
import { Box, Flex } from '@chakra-ui/react';

export const HeaderBar = (): JSX.Element => {
	return (
		<Flex direction={'row'} justifyContent="center">
			<Box pr={2}>
				<ColorModeSwitcher />
			</Box>
			<Box pr={2}>
				<SocialIcon rendericon={'github'} redirect={'https://github.com/cesarb1392'} />
			</Box>
			<Box pr={2}>
				<SocialIcon rendericon={'gitlab'} redirect={'https://gitlab.com/cesarb1392'} />
			</Box>
			<Box pr={2}>
				<SocialIcon rendericon={'linkedin'} redirect={'https://www.linkedin.com/in/cesar-represa-bautista'} />
			</Box>
		</Flex>
	);
};
